import { NotificationTypes } from 'kvinta/common/Enums';

export interface NotificationDetail {
  message: string;
  type: NotificationTypes;
}

export class NotificationManager {
  sendError(msg: string) {
    this.sendNotification(NotificationTypes.Critical, msg);
  }

  sendInformation(msg: string) {
    this.sendNotification(NotificationTypes.Information, msg);
  }

  sendSuccess(msg: string) {
    this.sendNotification(NotificationTypes.Success, msg);
  }

  sendWarning(msg: string) {
    this.sendNotification(NotificationTypes.Warning, msg);
  }

  private sendNotification(type: NotificationTypes, msg: string): void {
    const revisedMsg = msg === '{}' ? 'Failed to call the backend.' : msg;
    const ev = new CustomEvent('notification', {
      detail: { message: revisedMsg, type: type } as NotificationDetail,
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(ev);
  }
}
